import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      maxWidth: "792px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(11),
      paddingBottom: theme.spacing(25),
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(5),
        alignItems: "flex-start",
      },
    },
  });
export { styles };
