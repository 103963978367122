import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageProps } from "@src/types";
import { t } from "@src/utils/translations";

import ApplyForJobTemplate from "./ApplyForJobTemplate";

export interface ApplyForJobPageProps extends PageProps {}

class ApplyForJobPage extends React.PureComponent<ApplyForJobPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Apply for the job")}
          description={t(
            "We are always happy to hear from you! For any questions or recommendations do not hesitate to contact us.",
          )}
          keywords={[t("contact us"), t("open positions")]}
        />
        <ApplyForJobTemplate />
      </IndexLayout>
    );
  }
}

export default ApplyForJobPage;
