import { withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import ApplyForJobForm from "@src/components/ApplyForJobForm";
import SubmitSuccess from "@src/components/ApplyForJobForm/SubmitSuccess";
import PageLayout from "@src/layouts/PageLayout";

import { styles } from "./ApplyForJob.styles";

interface Props extends WithStyles<typeof styles> {}

interface State {
  isFormSubmitted: boolean;
}

class ApplyForJobTemplate extends React.PureComponent<Props, State> {
  public state = {
    isFormSubmitted: false,
  };

  public render() {
    const { classes } = this.props;

    if (this.state.isFormSubmitted) {
      return (
        <PageLayout className={classes.container}>
          <SubmitSuccess />
        </PageLayout>
      );
    }
    return (
      <PageLayout className={classes.container}>
        <ApplyForJobForm
          vacancyTitle="unspecified"
          onSubmit={this.handleSubmit}
          hasJobObjective
        />
      </PageLayout>
    );
  }

  private handleSubmit = () => {
    this.setState({ isFormSubmitted: true });
  };
}

export default withStyles(styles)(ApplyForJobTemplate);
